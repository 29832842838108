.App {
  width: 100%;
  height: 100%;
  overflow: hidden;
  align-items: stretch;
}

body {
  min-height: 100vh;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}