code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Works on Firefox */
/* * {
  scrollbar-width: thin;
  scrollbar-color: blue orange;
} */

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: #eeeeee;
}

*::-webkit-scrollbar-thumb {
  background-color: #9e9e9e;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #7b1fa2;
}